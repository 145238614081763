import React, { useCallback, useMemo, useState } from 'react'
import { AppBar, Container, Typography, IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Close, Menu } from '@mui/icons-material'
import useLockBodyScroll from 'react-use/lib/useLockBodyScroll'
import useLocation from 'react-use/lib/useLocation'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import NavigationGroup, {
  NavigationGroupProps,
} from '@talentinc/gatsby-theme-ecom/components/NavigationGroup/'
import NavigationButton from '@talentinc/gatsby-theme-ecom/components/Buttons/NavigationButton'
import ScrollProgress from '@talentinc/gatsby-theme-ecom/components/Post/ScrollProgress'
import Link from '@talentinc/gatsby-theme-ecom/components/Link'
import usePageData from '@talentinc/gatsby-theme-ecom/hooks/usePageData'
import usePageMeasurements from '@talentinc/gatsby-theme-ecom/hooks/usePageMeasurements'
import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'
import BlogNavigation from './BlogNavigation'
import { withBannerAccommodator } from '@talentinc/gatsby-theme-ecom/hocs/withBannerAccommodator'
import { ContentfulIds } from '../../types/contentfulAttributes'
import { useBETelemetry } from '../../hooks/useTelemetry'

interface Props {
  showBlogNavigation?: boolean
}

const NavTestHeaderV2: React.FC<Props> = ({ showBlogNavigation }) => {
  const { classes } = useStyles()
  const { navTestHeaderV2 } = usePageData()
  const brand = useBrand()
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState<string | false>(false)

  const telemetry = useBETelemetry() // Initialize telemetry hook

  const {
    header: [headerRef],
  } = usePageMeasurements()

  // Lock the body scroll if the mobile nav is open.
  useLockBodyScroll(open)
  const { pathname = '' } = useLocation()
  const pathNameWithoutLeadingSlash = useMemo(
    () => pathname.slice(1),
    [pathname]
  )

  const { t } = useTranslation()

  // HIW - How It Works
  const shouldReplaceWithHIW = useCallback(
    (contentfulId: string) =>
      [
        t('slugs.pricing', { product: brand.flagshipProduct?.toLowerCase() }),
        t('slugs.newpricing', {
          product: brand.flagshipProduct?.toLowerCase(),
        }),
        t('slugs.newpricingfour', {
          product: brand.flagshipProduct?.toLowerCase(),
        }),
        t('slugs.jobsearch', {
          product: brand.flagshipProduct?.toLowerCase(),
        }),
      ].some((slug) => pathname?.startsWith(slug)) &&
      [
        ContentfulIds.ResumeServicesNavGroup,
        ContentfulIds.CVServicesNavGroup,
      ].some((id) => id === contentfulId),
    [pathname, t, brand.flagshipProduct]
  )

  if (!navTestHeaderV2 || !brand) {
    return null
  }

  const handleHoverChange = (panel: string) => setExpanded(panel)

  const handleClickChange =
    (panel: string) =>
    (_: React.ChangeEvent<unknown>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <AppBar
      id="new-header"
      className={classes.appBar}
      component="header"
      elevation={0}
      position="sticky"
      color="inherit"
      ref={headerRef}
      data-gtm-role="header"
      style={{ display: 'none' }}
    >
      <Container className={classes.topBar} disableGutters>
        <Container className={classes.leftColumn} disableGutters>
          <Link className={classes.logo} href={'/'}>
            <img alt={brand.name} src={brand.whiteLogo?.file.url} />
          </Link>
          {!!navTestHeaderV2?.navigationLeftSide?.length && (
            <Container className={classes.navigationLeft} disableGutters>
              {navTestHeaderV2?.navigationLeftSide?.map((navGroup) => (
                <NavigationGroup
                  key={navGroup.contentful_id}
                  groupClass={classes.navigationGroup}
                  linkClass={classes.link}
                  navigationGroup={navGroup}
                  expanded={expanded}
                  handleChange={handleClickChange}
                  handleHoverChange={handleHoverChange}
                />
              ))}
            </Container>
          )}
        </Container>
        <Container className={classes.navigationRight} disableGutters>
          {navTestHeaderV2?.navigationRightSide?.map((navGroup) => (
            <React.Fragment key={navGroup.contentful_id}>
              {navGroup.__typename === 'ContentfulNavigationGroup' && (
                <Container className={classes.navGroupContainer} disableGutters>
                  {shouldReplaceWithHIW(navGroup.contentful_id) ? (
                    <HIWNavGroup />
                  ) : (
                    <NavigationGroup
                      accordionClass={classes.accordion}
                      accordionClasses={{
                        root: classes.accordion,
                        expandIconWrapper: classes.accordionSVG,
                        accordionSummaryContent:
                          classes.accordionSummaryContent,
                        accordionSummaryExpanded:
                          classes.accordionSummaryExpanded,
                        accordionDetails: classes.accordionDetails,
                        accordionDetailEntry: classes.accordionDetailEntry,
                        accEntryLink: classes.accEntryLink,
                      }}
                      linkClass={classes.link}
                      navigationGroup={navGroup}
                      expanded={expanded}
                      handleChange={handleClickChange}
                      handleHoverChange={handleHoverChange}
                    />
                  )}
                </Container>
              )}
              {navGroup.__typename === 'ContentfulButton' && (
                <NavigationButton
                  link={navGroup}
                  className={classes.navButton}
                  onClick={() => {
                    telemetry.track({ event: 'click_buy_now_header' }) // Track the "Buy Now" event
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </Container>
        <IconButton
          aria-label={open ? 'close' : 'menu'}
          className={classes.iconButton}
          color="inherit"
          onClick={() => setOpen((open) => !open)}
          size="large"
        >
          <Typography>
            <strong>{t('navTest.menu')}</strong>&nbsp;
          </Typography>
          {open ? <Close /> : <Menu />}
        </IconButton>
      </Container>
      {showBlogNavigation && <BlogNavigation className={classes.blogNav} />}
      {open && (
        <Container className={classes.drawer} disableGutters>
          {showBlogNavigation && <BlogNavigation />}
          <Container className={classes.drawerLinkContainer} disableGutters>
            <Container disableGutters>
              {navTestHeaderV2?.navigationRightSide?.map((navGroup) => (
                <React.Fragment key={navGroup.contentful_id}>
                  {navGroup.__typename === 'ContentfulNavigationGroup' &&
                    (shouldReplaceWithHIW(navGroup.contentful_id) ? (
                      <HIWNavGroup groupClass={classes.navigationGroup} />
                    ) : (
                      <NavigationGroup
                        accordionClass={classes.accordion}
                        accordionClasses={{
                          root: classes.accordion,
                          expandIconWrapper: classes.accordionSVG,
                          accordionSummaryContent:
                            classes.accordionSummaryContent,
                          accordionSummary: classes.accordionSummary,
                          accordionSummaryExpanded:
                            classes.accordionSummaryExpanded,
                          accordionDetails: classes.accordionDetails,
                          accordionDetailEntry: classes.accordionDetailEntry,
                          accEntryLink: classes.accEntryLink,
                        }}
                        groupClass={classes.navigationGroup}
                        linkClass={classes.link}
                        navigationGroup={navGroup}
                        key={navGroup.contentful_id}
                        expanded={expanded}
                        handleChange={handleClickChange}
                        handleHoverChange={handleHoverChange}
                      />
                    ))}
                  {!navTestHeaderV2.hideBuyNowButton?.includes(
                    pathNameWithoutLeadingSlash
                  ) &&
                    navGroup.__typename === 'ContentfulButton' && (
                      <Container
                        className={classes.drawerButtonContainer}
                        disableGutters
                      >
                        <NavigationButton
                          className={classes.navigationButtonDrawer}
                          link={navGroup}
                          onClick={() => {
                            setOpen(false)
                            telemetry.track({ event: 'click_buy_now_header' }) // Track the "Buy Now" event
                          }}
                        />
                      </Container>
                    )}
                </React.Fragment>
              ))}
            </Container>
          </Container>
        </Container>
      )}
      {!open && showBlogNavigation && (
        <ScrollProgress className={classes.ScrollProgress} />
      )}
    </AppBar>
  )
}

// HIW - How It Works
const HIWNavGroup: React.FC<Omit<NavigationGroupProps, 'navigationGroup'>> = (
  props
) => {
  const { t } = useTranslation()
  const { flagshipProduct } = useBrand()
  const tRef = 'navigation.hiw'

  return (
    <NavigationGroup
      key="#how-it-works"
      navigationGroup={{
        title: t(`${tRef}.title`),
        text: '',
        links: [
          {
            contentful_id: '#how-it-works',
            text: t(`${tRef}.linkText`),
            action: null,
            __typename: 'ContentfulInternalLink',
            slug: t('slugs.howItWorks', {
              product: flagshipProduct?.toLowerCase(),
            }),
            icon: null,
            codeClasses: null,
            mixpanelTracking: null,
          },
        ],
        contentful_id: '#navgroup',
        category: null,
        variant: null,
        __typename: 'ContentfulNavigationGroup',
      }}
      {...props}
    />
  )
}

const useStyles = makeStyles()((theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.dark,
    whiteSpace: 'pre',
    '& > *': {
      color: theme.palette.background.default,
    },
    '& a': {
      color: theme.palette.background.default,
      '&:hover, &:active': {
        color: theme.palette.background.default,
      },
    },
  },
  blogNav: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  drawer: {
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      backgroundColor: theme.colors.blue['a600'],
      display: 'unset',
      height: 'calc(100vh - 3.59em)',
      paddingBottom: '.5em',
      position: 'absolute',
      top: '3.59em',
    },
    [theme.breakpoints.down('md')]: {
      borderTop: 'none',
    },
  },
  drawerButtonContainer: {
    paddingInline: '6%',

    [theme.breakpoints.down('lg')]: {
      marginBlock: '3rem',
    },
  },
  drawerLinkContainer: {
    height: 'calc(100% - 11em)',
    overflow: 'scroll',
  },
  iconButton: {
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      display: 'unset',
      padding: '0',
    },
    '&:focus': {
      borderRadius: '0',
    },
  },
  link: {
    fontSize: '.94rem',
    margin: '0 2.5%',
    display: 'flex',
  },
  logo: {
    display: 'flex',
    fontSize: '1rem',
    margin: '0',
    height: '1.4rem',
    width: 'auto',
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  navGroupContainer: {
    width: 'auto',
    margin: 0,

    // To stop the flicker on hover
    '&:nth-child(2)': {
      minWidth: '4.1rem',
    },

    // To stop the flicker on hover
    '&:nth-child(3)': {
      minWidth: '6.7rem',
    },
  },
  navigationButtonDrawer: {
    paddingBlock: '0.75rem',

    [theme.breakpoints.down(768)]: {
      width: '100%',
    },
  },
  navigationGroup: {
    width: '83%',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      padding: '0 6% 0 6%',
      width: '100%',
      marginBlock: '3rem',

      '& > *': {
        fontSize: '1.875rem',
        fontWeight: 700,
        margin: '0',
      },
    },
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'row',
    width: 'max-content',
    margin: 0,
  },
  navigationLeft: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'unset',
    width: '38em',

    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  navigationRight: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '2.5rem',
    margin: 'unset',
    maxWidth: '65%',

    [theme.breakpoints.down('lg')]: {
      justifyContent: 'flex-end',
      width: '100%',
      display: 'none',
      '& > *': {
        margin: '0 1em',
      },
    },
  },
  topBar: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '.94rem',
    justifyContent: 'space-between',
    maxWidth: '100%',
    padding: '0 6% 0 6%',
    zIndex: 100,
    [theme.breakpoints.down('lg')]: {
      padding: '1rem 6%',
    },
    '@media (min-width: 1456px)': {
      padding:
        '0 calc((100% - 91rem)/2 + 5.5rem) 0 calc((100% - 91rem)/2 + 5.5rem)',
    },
  },
  accordion: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.default,

    [theme.breakpoints.down('lg')]: {
      backgroundColor: theme.colors.blue['a600'],
      marginBlock: '3rem',
    },
  },
  accordionSVG: {
    fill: theme.palette.background.default,
  },
  accordionSummary: {
    border: 'none',
    marginBottom: '0.875rem',

    '&&': {
      minHeight: 'fit-content',
    },
  },
  accordionSummaryContent: {
    [theme.breakpoints.down('lg')]: {
      '&&': {
        margin: 0,
        fontSize: '1.875rem',
        fontWeight: 700,
        padding: 0,
      },
    },
  },
  accordionSummaryExpanded: {
    fontWeight: 600,

    [theme.breakpoints.down('lg')]: {
      fontSize: '1.875rem',
      fontWeight: 700,
      padding: '1rem 0',
    },
  },
  accordionDetails: {
    backgroundColor: theme.colors.blue['a600'],

    [theme.breakpoints.down('lg')]: {
      paddingBottom: 0,
    },

    [theme.breakpoints.up('lg')]: {
      borderRadius: '8px',
      marginTop: '-1rem',
      zIndex: 1,
      backgroundColor: theme.palette.background.default,
    },
  },
  accordionDetailEntry: {
    fontSize: '1.25rem',

    [theme.breakpoints.up('lg')]: {
      fontSize: '1.0625rem',
      color: `${theme.palette.text.secondary}`,
    },

    [theme.breakpoints.down('lg')]: {
      paddingBlock: '0.5rem',
    },
  },
  accEntryLink: {
    '&&&': {
      [theme.breakpoints.down('lg')]: {
        color: theme.colors.white[100],
      },

      '&:hover': {
        color: theme.palette.action.active,
      },
    },
  },
  ScrollProgress: {
    backgroundColor: theme.palette.primary.dark ?? theme.palette.text.hint,
  },
  navButton: {
    paddingBlock: '0.75rem',
    lineHeight: '1.375rem',
    marginBlock: '1rem',
  },
}))

export default withBannerAccommodator(NavTestHeaderV2)
